import { ConnectedProps, connect } from "react-redux";
import Footer from "../components/navigation/footer";
import Alert from "../components/alert/alert";
import { get_network_id, loadWeb3 } from "../redux/actions/web3";
import { get_my_user_details } from "../redux/actions/user";
import {
  loginGoogle,
  userGoogle,
  verifyGoogle,
} from "../core/redux/actions/google_action";
import MainLoading from "../components/loaders/main_loading";
import {
  getUserAccount,
  logoutUserAccount,
  verifyUserAccount,
} from "../pages/auth/redux/actions/user_account_action";
import NoAuthNavBar from "../core/components/navigation/no_auth_navbar";
declare let window: any;

const NoAuthFullWidthLayout: React.FC<PropsFromRedux> = ({
  children,
  loading,
  loadWeb3,
  get_network_id,
  my_user,
  get_my_user_details,
  google_state,
  user_account_state,
  loginGoogle,
  userGoogle,
  verifyGoogle,
  verifyUserAccount,
  getUserAccount,
  logoutUserAccount,
}: PropsFromRedux) => {
  function handleChainChanged(_chainId: any) {
    window.location.reload();
  }

  if (window.ethereum) {
    window.ethereum.on("chainChanged", handleChainChanged);
  }

  const handleLogOut = async () => {
    await logoutUserAccount();
  };

  return (
    <div className="dark:bg-slate-700 min-h-screen">
      <NoAuthNavBar logOut={handleLogOut} />
      <div className="min-h-100 max-w-7xl mx-auto pt-24 pb-24">
        {google_state?.user?.email}
        <div className="max-w-7xl mx-auto">{children}</div>
      </div>
      <Footer />
      <Alert />

      {loading && (
        <div className="dark:bg-slate-800/80 bg-blue-800/80 fixed z-50 inset-0 flex items-center">
          <MainLoading />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  my_user: state.google_state?.user,
  user_account_state: state.user_account_state,
  google_state: state.google_state,
  loading: false,
});

const mapDispatchToProps = {
  loadWeb3,
  get_network_id,
  get_my_user_details,
  loginGoogle,
  userGoogle,
  verifyGoogle,
  verifyUserAccount,
  getUserAccount,
  logoutUserAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & {
  children?: React.ReactNode;
};

export default connector(NoAuthFullWidthLayout);
