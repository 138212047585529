import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import NoAuthFullWidthLayout from "../../layouts/no_auth_full_width_layout";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import ThreeJSComponent from "./components/cool-circles";
import Part1 from "./components/part_1";
import { FaWhatsapp, FaPhone, FaUser } from "react-icons/fa";

const Landing: React.FC<PropsFromRedux> = ({}: PropsFromRedux) => {
  const [dark, setDark] = useState(false);
  const [feel_of_view, setFeelOfView] = useState<number>(70);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const clientX = event.clientX;
    const clientY = event.clientY;
    // Calculate the feel_of_view based on the client cursor position
    const newFeelOfView = Math.floor(clientX / clientY);
    console.log(newFeelOfView);
    //setFeelOfView(newFeelOfView);
  };

  const navigation = {
    solutions: [
        { name: "Cursos", href: "/cursos" },
        { name: "Academias", href: "/academias" },
        { name: "Tienda", href: "/tienda" },
        // { name: 'Desarrollo Web', href: '/servicios/desarrollo_web' },
    ],
    support: [
        // { name: 'Guias', href: '/guias' },
        { name: "Ayuda", href: "/ayuda" },
        { name: "Blog", href: "/blog" },
    ],
    company: [
        { name: "Nosotros", href: "/nosotros" },
        { name: "Contacto", href: "/contacto" },
    ],
    legal: [
        { name: "Terminos", href: "/terminos" },
        { name: "Privacidad", href: "/privacidad" },
    ],
    social: [
        {
            name: "Instagram",
            href: "https://www.instagram.com/casabagre",
            icon: (props:any) => <i className="fa fa-instagram text-2xl"></i>,
        },
        {
            name: "YouTube",
            href: "https://youtube.com/@troika4621",
            icon: (props:any) => <i className="fa fa-youtube text-2xl"></i>,
        },
    ],
  };

  return (
    <NoAuthFullWidthLayout>
      {/* <div className="absolute z-10">
        <ThreeJSComponent />
      </div> */}
      <div className="relative items-center justify-center flex flex-wrap w-full py-12 md:py-16 text-slate-700 dark:text-stone-50">
        <div className="flex flex-col flex-wrap w-full">
          <h2 className="flex md:hidden text-4xl px-3 mx-auto">
            <strong className="text-acidjelly-blue-700">ACID</strong>{" "}
            <strong className="text-acidjelly-red">JELLY</strong>
          </h2>
          <h2 className="z-20 pt-2 text-2xl md:text-4xl text-center w-full font-bold">
            Somos tu mejor opción
          </h2>
          <p className="mx-auto p-1">Entregamos exactamente lo que quieres</p>
          <div className="flex flex-wrap w-full md:w-1/2 mx-auto">
            {/* here we have the link to open a whatsapp convesation whit our number +51951762872 */}
            <a
              href="https://api.whatsapp.com/send?phone=51958115716&text=Hola %20:D!"
              target="_blank"
              className="gap-3 flex items-center mx-auto mt-4 px-4 py-2 dark:bg-acidjelly-blue-700 bg-acidjelly-blue-800 rounded-lg font-bold text-white"
              rel="noreferrer"
            >
              <FaWhatsapp /> Whatsapp
            </a>
            {/* here one to call us */}
            <a
              href="tel:+51958115716"
              className="gap-3 flex items-center md:hidden mx-auto mt-4 px-4 py-2 dark:bg-acidjelly-blue-800 bg-acidjelly-blue-700 rounded-lg font-bold text-white"
            >
              <FaPhone />
              Llamanos
            </a>
            <a
              href="/login"
              target="_blank"
              className="gap-3 flex items-center mx-auto mt-4 px-4 py-2 dark:bg-acidjelly-blue-800 bg-acidjelly-blue-700 rounded-lg font-bold text-white"
              rel="noreferrer"
            >
              <FaUser /> Acceder
            </a>
          </div>
        </div>

      </div>

      {/* Tunel Section for Cinema de Terror event */}
      <div className="w-full bg-gray-100 text-gray-900 py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Cinema de Terror en la dark kitchen de Acid Jelly en Sala Eclipse
          </h2>
          <p className="mb-2 font-extrabold">Chef: Chef Insetcero</p>
          <p className="mb-2 font-extrabold">Carta: Proximamente</p>

          <ul className="mb-4">
            <li>compra por nuestra carta online</li>
            <li>dinos el numero de tu butaca</li>
            <li>cuando termines nos avisas ;D</li>
          </ul>
          <p className="mb-2 font-extrabold">Cartelera: Proximamente</p>
          <p className="mb-2">FECHA: PROXIMAMENTE, NOS DIO FIEBRE</p>
          <div className="flex items-center flex-col">
            <div className="w-100 md:w-60 overflow-hidden flex items-center justify-center">
              <img 
                src="/images/clients/sala_eclipse/logo_black.png" 
                alt="Casa Bagre Logo" 
                className="object-cover w-full h-full"
              />
            </div>
            <div className="w-100 dark:border-dark-second px-2 flex flex-col items-center gap-5 pt-2">
                <div className="flex space-x-6 md:order-2">
                    {navigation.social.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            target="_blank"
                            className="text-gray-400 hover:text-gray-400 hover:text-acidjelly-red-50"
                        >
                            <span className="sr-only">{item.name}</span>
                            <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    ))}
                </div>
                <a
                  href="https://maps.app.goo.gl/KLgxSDbZL5yFRzCT7" // Replace with the actual Google Maps link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-8 text-stone-500 dark:text-stone-500 md:mt-0 hover:text-acidjelly-blue-800 dark:hover:text-acidjelly-blue-50 md:order-1"
                >
                  <em>Jirón Moquegua 112, int 110, Cercado de Lima, Lima {'(Click para Ir)'}</em>
                </a>
            </div>
          </div>
        </div>
      </div>

      {/* dark kitchen section using tailwind css  */}
      <div className="w-full bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-6">Dark Kitchen</h2>
          <p className="text-center mb-8">
          Descubre cómo nuestra cocina oscura puede ayudarte a expandir tu negocio.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Alta Calidad</h3>
          <p>
          Ofrecemos ingredientes de la más alta calidad para garantizar la satisfacción de tus clientes.
          </p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Entrega Rápida</h3>
          <p>
          Nuestro sistema de logística asegura que tus pedidos lleguen a tiempo, siempre.
          </p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Soporte 24/7</h3>
          <p>
            Estamos disponibles en todo momento para resolver cualquier duda o inconveniente.
          </p>
        </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full flex flex-wrap items-center justify-center relative min-h-screen p-2">
        <div className="w-full z-10 text-4xl font-bold text-gray-900 dark:text-gray-50">
          <h2>Legal App</h2>
          <h2>Version 1.0.0</h2>
          <small>
            Stack:
            <span className="p-1"></span>
            <em>{"React(Ts) + Django + Any Database"}</em>
          </small>
        </div>
        <div className="w-full flex">
          <div className="flex items-center w-full sm:w-full md:w-1/2 lg:w-1/2 p-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias iusto
            minima voluptates, nemo aut a cum amet culpa rem voluptatem
            molestias corrupti quia, dicta explicabo. Quae nostrum ut repellat
            officia.
          </div>
          <div className="w-full sm:w-full md:w-1/2 lg:w-1/2">
            <img
              className="w-full mx-auto rounded-3xl"
              src="/images/services/legal_app_under_construction.jpeg"
              alt=""
            />
          </div>
        </div>

        <NavLink
          to="/login"
          className="relative flex flex-col items-center justify-center h-52 w-1/2 cursor-pointer"
        >
          <div className="text-4xl flex flex-col items-center justify-center z-10 h-full w-full text-center bg-stone-300/50 dark:bg-slate-900/60 rounded-3xl">
            <h2>Ir al sistema</h2>
          </div>
        </NavLink>
      </div> */}
      {/* <Part1 /> */}
    </NoAuthFullWidthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Landing);
