import React from 'react'
import FullWidthLayout from '../layouts/full_width_layout'

function NotFound() {

  const urlPath = window.location.pathname;
  const pageName = urlPath.split('/').filter(Boolean).join(' ');

  return (
    <FullWidthLayout>
      <div className='flex flex-col items-center justify-center inset-0 absolute'>
        <h1 className='w-100 text-center text-4xl text-acidjelly-blue-700 dark:text-acidjelly-red'>{`${pageName}`}</h1>
        <p className='text-acidjelly-red-800 dark:text-acidjelly-blue-300'>;-; mentira</p>
      </div>
    </FullWidthLayout>
  )
}

export default NotFound